import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
import "./assets/style/mainStyle.css"
// import vueMoment from 'vue-moment';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  axios,
  moment,
  // vueMoment,
  VueAxios,
  render: h => h(App)
}).$mount('#app')
