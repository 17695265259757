<template>
  <div class="pa-3 ">
    <v-card
      outlined
      style="padding: 10px; margin: 10px 0px;"
      v-if="!isSearch1"
    >
      <div style="width: 100%; display: flex; align-items: center;">
        <img src="@/assets/help-box.svg" style="height: 25px; min-width: 25px;">
        <div class="text-h6 font-weight-medium" style="margin-left: 5px;">
          HOW TO USE
        </div>
      </div>
      <div>
        Step 1. Enter the IP address you want to search in the textbox. 
      </div>
      <div>
        Step 2. Press the Enter key to search the Blacklist IP address database and view the results.
      </div>
      <br>
      <div>
        * The database used to scan for blacklist IP address is updated daily to maintain up-to-dateness.
      </div>
      <div>
        * The screen below shows an example of search results for a blacklisted IP address.
      </div>
      
    </v-card>

    <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" >{{ resultSearchTimeStr && `search result time : ${resultSearchTimeStr}` }} </div>
    <div style="font-weight:bold; border-bottom: 1px solid #d3d4e6;" class="mt-2 mb-2" > {{threatsFound.length}} threats detected in {{ threat.osint_db_count}} OSINT DB</div>
      <div class="" style="width:100%;">
        <span class="pl-1 pr-1 mb-4">Current Blacklist Status:</span>
        <span><v-chip class=" font-weight-bold mr-1 " label :color="listed?'red darken-2':'green'" text-color="white" x-small style="font-size:76%; " >{{listed? 'LISTED':'NOT LISTED'}}</v-chip></span>
      </div>
      <div class="mt-1 mb-1 pl-1" > Detections by OSINT DB categories</div>
            <div  class="pl-2 pb-3 " style=" width:100%; border-bottom: 1px solid #d3d4e6;">
                <table >
                <tbody>
                    <tr
                    v-for="(item, name) in category"
                    :key="name">
                    <v-row class="pa-3">
                        <td><v-icon small class="pr-1">mdi-circle-medium</v-icon></td>
                        <td>{{ name }}</td>
                        <td class="text-left pl-2">( {{ item.value }} / {{ item.max }} )</td>
                    </v-row>
                    </tr>
                </tbody>
                </table>
            </div>

        <div class="pl-1 pr-1 mb-4 pt-3">
          <div v-if="threatsFound.length>0">
            <v-card 
                class="mt-1 pt-2 mb-2"
                outlined
                v-for="(threat, index) in threatsFound" :key="index"
                color="#ECEFF1"
                style="border:1px solid #d3d4e6;">
                <v-card-text class="pt-0 pb-2">
                  <div >
                    <div>
                      Category: {{threat.category ? threat.category : 'N/A'}}
                    </div>
                    <div>
                      OSINT DB: {{threat.maintainer_url ? threat.maintainer_url : 'N/A'}}
                    </div>
                    <div>
                    Maintainer: {{threat.maintainer ? threat.maintainer : 'N/A'}}
                    </div>
                    <div>
                    Current Status: {{threat.current_status ? threat.current_status : 'N/A'}}
                    </div>
                    <div>
                    First Seen: {{threat.first_seen ? threat.first_seen : 'N/A'}}
                    </div>
                    <div>
                    Last Added: {{threat.last_added ? threat.last_added : 'N/A'}}
                    </div>
                    <div>
                    Last Removed: <span v-if="threat.last_removed">{{threat.last_removed}}</span><v-chip v-else class="font-weight-bold mr-1" label color="red darken-2" text-color="white" x-small style="font-size:76%; " >NOT YET</v-chip>
                    </div>
                  </div>
                </v-card-text>
            </v-card>
          </div>
        </div>  

  </div>
</template>

<script>
import defaultThreat from '@/assets/json/default.json';

export default {
  data () {
    return {
      listed: false,
      threatsFound:[],
      category: null,
      isSearch1:false
    } 
  },
  watch:{
    isSearch:{
      immediate:true,
      handler(value){
        this.isSearch1=value;
      }
    },
    threat:function(threat){
      console.log(threat);
      this.arrange(threat);
    }
  },
  props: {
    threat: Object,
    resultSearchTimeStr: String,
    isSearch: Boolean
  },
  created(){
    // console.log(this.threat)
    this.arrange(defaultThreat.threat);
  },
  methods:{
    arrange:function(threat){

      console.log("mounted : ", threat)
      this.category = {};
      Object.entries(threat.osint_category_count_data).forEach(entry => {
        const [key, value] = entry;
        console.log(entry);
        console.log(key, value);
        this.category[key] = {
          value : 0,
          max : value,
        }
      });
    
      console.log("this.category : ", this.category);
    
      if(threat.blacklisted_count){
        if(threat.blacklisted_count > 0 || threat.currently_blacklisted_count >0){
          this.listed = true;
        }
      }
      if (threat.results.length!=0){
        console.log(threat.results[0].hits)
        this.threatsFound = threat.results[0].hits;
        threat.results[0].hits.forEach(hit => {
          switch (hit.category) {
            case `abuse`:
              this.category.abuse.value ++
              break;
            case `anonymizers`:
              this.category.anonymizers.value ++
              break;
            case `attacks`:
              this.category.attacks.value ++
              break;
            case `malware`:
              this.category.malware.value ++
              break;
            case `organizations`:
              this.category.organizations.value ++
              break;
            case `reputation`:
              this.category.reputation.value ++
              break;
            case `spam`:
              this.category.spam.value ++
              break;
            case `unroutable`:
              this.category.unroutable.value ++
              break;
            default:
              break;
          } 
        });
      }else{
        this.threatsFound=[];
      }
    }
  },
  mounted(){
  }
}

</script>

