<!-- eslint-disable vue/multi-word-component-names -->
 
<template>
  <div class="home">
    <v-navigation-drawer app clipped mobile-breakpoint="960">
      <v-container fluid>
        <v-col col="12" class="pa-0">
          <div
            style="
              width: 30%;
              margin-right: 15px;
              margin-left: 38px;
              margin-top: 30px;
              float: left;
              display: contents;
            "
          >
            <iframe
              style="min-width: 220px"
              width="100%"
              height="210"
              src="https://www.youtube.com/embed/XrpjJBbNigc?si=GQQwYq5ri4Smp6-3"
              frameborder="0"
              allow="encrypted-media"
              allowfullscreen
            ></iframe>
          </div>
        </v-col>
      </v-container>

      <v-divider></v-divider>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              ref="textField"
              class="pt-0"
              v-model="search"
              label="Search IP"
              placeholder="Search IP"
              persistent-hint
              hint="Enter the IP address to search"
              type="text"
              @click:append-outer="execSearch"
              @click:clear="clearSearch"
              @keydown.enter="execSearch"
              @keydown.esc="clearSearch"
              @focus="handleFocus"
              @blur="handleBlur"
            >
              <template v-slot:append-outer>
                <img
                  :src="require('@/assets/magnify.svg')"
                  alt="Search Icon"
                  style="width: 24px; height: 24px; cursor: pointer;"
                  @click="execSearch"
                  :class="['custom-icon', { 'custom-icon-focused': isFocused }]"
                />
              </template>
              <template v-slot:append>
                <img
                  :src="require('@/assets/close.svg')"
                  alt="Search Icon"
                  style="width: 24px; height: 24px; cursor: pointer;"
                  @click="clearSearch"
                  :class="['custom-icon', { 'custom-icon-focused': isFocused }]"
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-container
        class="media"
        style="padding-bottom: 0px !important; text-align: right"
      >
        <v-btn
          @click="introduction()"
          text
          class="text-decoration-underline text-subtitle-1 text-right"
          >INTRODUCTION</v-btn
        >
        <v-btn
          @click="howPage()"
          text
          class="text-decoration-underline text-subtitle-1 text-right"
          >HOW TO USE</v-btn
        >
      </v-container>

      <div style="position: absolute; bottom: 0px; width: 100%">
        <v-divider></v-divider>
        <v-card-title
          style="
            min-height: 40px !important;
            padding: 0px !important;
            justify-content: center !important;
            margin-top: 10px;
          "
        >
          <a
            v-for="(icon, index) in icons"
            :href="icon.link"
            v-bind:key="index"
            target="_blank"
            style="
              width: 50px;
              display: flex;
              text-decoration: none;
              justify-content: center;
              align-items: center;
            "
            class="snsBtn"
          >
            <v-hover v-slot="{ hover }">
              <v-btn
                :class="[[hover ? icon.color : 'defaultColor'], 'mx-4']"
                icon
              >
                <img :src="icon.src" style="width: 24px; height: 24px" />
              </v-btn>
            </v-hover>
          </a>
        </v-card-title>
        <div class="pa-0 text-center">info@wikisecurity.net</div>
        <div class="pa-1 text-center text-caption">
          Don't Miss To Follow Us On Our Social Networks Official Accounts.
        </div>
      </div>
    </v-navigation-drawer>
    <v-container md>
      <v-row>
        <v-col col="12">
          <!-- <v-sheet outlined class="d-md-none mb-2" elevation="1">
            <div class="pa-4">
              <v-text-field class="pt-0" v-model="search" 
                label="Search" placeholder="Enter an IP address" type="text" @click:clear="clearSearch"
                @keydown.enter="execSearch" @keydown.esc="clearSearch"
                ></v-text-field>
            </div>
          </v-sheet> -->
          <OSINTDetails
            :threat="threat"
            v-if="howToUse"
            :isSearch="isSearch"
            :resultSearchTimeStr="resultSearchTimeStr"
          />
          <default-page v-if="defaultPage"></default-page>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
// import VulnTable from "../components/home/VulnTable.vue";
import OSINTDetails from "../components/OSINTDetails.vue";
import moment from "moment";
import defaultThreat from "@/assets/json/default.json";
import DefaultPage from "./defaultPage.vue";

export default {
  name: "Home",
  components: {
    // VulnTable,
    OSINTDetails,
    DefaultPage,
  },
  data: () => ({
    defaultPage: true,
    howToUse: false,
    threat: null,
    isSearch: false,
    // results: [],
    search: "8.8.8.8",
    searchedTerm: null,
    searching: false,
    data: null,
    resultSearchTimeStr: "19ms",
    isFocused: false,
    tableHeaders: [
      { text: "IP", value: "ip" },
      { text: "Categories", value: "categories" },
      { text: "Currently Blacklisted", value: "currently_blacklisted" },
      { text: "Hits Count", value: "hits_count" },
      { text: "First Seen", value: "first_seen" },
      { text: "Last Added", value: "last_added" },
      { text: "", value: "hits" },
    ],
    icons: [
      {
        src: require("@/assets/facebook.svg"),
        link: "https://www.facebook.com/people/Wiki-Security-INC/100064205460242/",
        color: "facebook",
      },
      {
        src: require("@/assets/youtube.svg"),
        link: "https://www.youtube.com/channel/UCddHTMigvEFbI8Zu29ODwAQ?view_as=subscriber",
        color: "youtube",
      },
      {
        src: require("@/assets/linkedin.svg"),
        link: "https://in.linkedin.com/company/wiki-security",
        color: "linked",
      },
    ],
  }),
  created() {
    var firstThreat = defaultThreat.threat;
    this.threat = firstThreat;
    console.log(this.threat);
  },
  methods: {
    introduction: function () {
      this.defaultPage = true;
      this.howToUse = false;
    },
    howPage: function () {
      this.defaultPage = false;
      this.howToUse = true;
      this.isSearch = false;
      var firstThreat = defaultThreat.threat;
      this.threat = firstThreat;
    },
    clearSearch() {
      console.log("clearSearch");
      this.search = "";
      this.threat = null;
      this.resultSearchTimeStr = "";
    },
    handleFocus() {
    this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
    durationAsString(ms, maxPrecission = 3) {
      const duration = moment.duration(ms);

      const items = [];
      items.push({ timeUnit: "d", value: Math.floor(duration.asDays()) });
      items.push({ timeUnit: "h", value: duration.hours() });
      items.push({ timeUnit: "m", value: duration.minutes() });
      items.push({ timeUnit: "s", value: duration.seconds() });
      items.push({ timeUnit: "ms", value: duration.milliseconds() });

      const formattedItems = items.reduce(
        (accumulator, { value, timeUnit }) => {
          if (
            accumulator.length >= maxPrecission ||
            (accumulator.length === 0 && value === 0)
          ) {
            return accumulator;
          }

          accumulator.push(`${value}${timeUnit}`);
          return accumulator;
        },
        []
      );

      return formattedItems.length !== 0 ? formattedItems.join(" ") : "-";
    },
    execSearch() {
      console.log("execSearch");
      this.howPage();
      this.threat = null;
      this.searching = true;
      this.searchedTerm = this.search;

      if (this.search !== null && this.search.length > 0) {
        console.log(this.search);
        var startTime = new Date().getTime();
        var response = null;
        axios
          .post("/search.php", { keyword: this.search })
          .then((res) => {
            console.log(res);
            response = res;
            var endTime = new Date().getTime();
            var resultSearchTime = endTime - startTime;

            if (response.data.threat && response.data.threat.results) {
              console.log("response");
              this.threat = response.data.threat;
              this.isSearch = true;
              this.resultSearchTimeStr =
                this.durationAsString(resultSearchTime);
            } else {
              const { errors } = response.data.threat;
              this.isSearch = false;
              this.introduction();
              alert(errors);
            }
          })
          .catch((error) => {
            console.log(error);
            this.searching = "red";
          });
        // var response = await axios.post('/search.php', { keyword: this.search });

        // this.searching = false;
      }
      // else {
      //   this.searching = false;
      // }
    },
  },
};
</script>

<style scoped>
.custom-icon:hover,
.custom-icon.custom-icon-focused {
  filter: invert(24%) sepia(83%) saturate(7474%) hue-rotate(193deg) brightness(99%) contrast(119%);
}

.mx-4 {
  transition: all ease 0.3s;
}

@media (min-width: 960px) {
  .media {
    margin-top: -17px;
  }
}

@media (min-width: 1560px) {
  .media {
    margin-top: 10px;
  }
}
</style>
