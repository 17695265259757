<template>
  <div>
    <v-container fluid style="height: 100%; overflow-y:hidden">
            <v-row style="height: 100%; overflow-y:hidden">
                <v-col >
                    <v-card elevation="0" outlined style="display:flex; flex-direction:column; height:89vh; overflow-y:hidden">
                        <v-card-text 
                            style="position:relative"
                        >
                        <div
                            @mouseover="hoveringPart1 = true" 
                            @mouseout="hoveringPart1 = false">
                            <div v-if="!hoveringPart1 && !part1 || hoveringPart1 && part1" class="text-container">
                                This website allows anyone to search blacklist IP addresses without logging in. 
                                Currently, it uses FireHOL's database, which provides over 100 million blacklist IP addresses, 
                                but databases from MISP, Darknet, and others will be added in the future
                            </div>
                            <div v-if="hoveringPart1 && !part1 || !hoveringPart1 && part1" class="text-container">
                                이 웹사이트는 누구나 로그인 없이 blacklist IP Address를 검색할수 있으며, 
                                현재는 1억 개 이상의 blacklist IP Address를 제공하는 FireHOL의 Database를 이용하고 있으나, 
                                MISP, Darknet 등의 Database들을 추가할 예정이다.
                            </div>
                        </div>
                            
                            <v-btn depressed @click="part1 = !part1" 
                            style="min-width: 20px; height: 20px; padding: 0px !important; justify-self:end; position:absolute; left:25px; bottom:-10px">
                                <img src="@/assets/google-translate.svg" style="width: 20px; height: 20px;">
                            </v-btn>
                        </v-card-text>
                    
                        <div style="margin-top:60px; width:50%; justify-self:center; align-self:center;">
                            <iframe width="100%" height="500" 
                            src="https://www.youtube.com/embed/Yuw4Sv5Xg64?si=ycMEYrKi_UQtVrad" 
                            title="YouTube video player" frameborder="0" allow="encrypted-media;" allowfullscreen></iframe>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
  </div>
</template>

<script>
export default {
    data:()=>({
        part1: false,
        hoveringPart1: false,
    }),

}
</script>

<style>
.text-container {
    color: black;
    height: 45px; 
    display: flex;
    align-items: center;
    line-height: 1.8; 
    padding: 10px; 
    box-sizing: border-box;
}
</style>